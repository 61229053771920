import * as React from "react"
import { Link } from "react-router-dom"

const MobileMenu = ({ isPromoOver, setShowMenu, scrollToAnchor }) => {
	return (
		<div className="fixed top-0 left-0 z-[800] h-[100vh] w-[100vw] items-center bg-red text-center ">
			<div className="relative flex h-[100vh] items-center align-middle">
				<img
					src={process.env.PUBLIC_URL + "/images/icon-close.svg"}
					alt="Menu"
					loading="lazy"
					width="83"
					height="73"
					className=" absolute top-4 right-4 w-[35px] cursor-pointer"
					onClick={() => setShowMenu(false)}
				/>
				<ul className="mx-auto">
					<li className="my-4">
						<Link
							to={process.env.PUBLIC_URL + "/teilnahmebedingungen"}
							className="header-item-mobile"
							onClick={() => {
								try {
									// eslint-disable-next-line
									let dataLayer = window["dataLayer"] || []
									dataLayer.push({ event: "header", link: "teilnahmebedingungen" })
								} catch (e) {}
								return setShowMenu(false)
							}}
						>
							Teilnahmebedingungen
						</Link>
					</li>
					<li className="my-4">
						<Link
							to={process.env.PUBLIC_URL + "/faq"}
							className="header-item-mobile"
							onClick={() => {
								try {
									// eslint-disable-next-line
									let dataLayer = window["dataLayer"] || []
									dataLayer.push({ event: "header", link: "faq" })
								} catch (e) {}
								return setShowMenu(false)
							}}
						>
							FAQS
						</Link>
					</li>
					<li className="my-4">
						<Link
							to={process.env.PUBLIC_URL + "/kontakt"}
							className="header-item-mobile"
							onClick={() => {
								try {
									// eslint-disable-next-line
									let dataLayer = window["dataLayer"] || []
									dataLayer.push({ event: "header", link: "kontakt" })
								} catch (e) {}
								return setShowMenu(false)
							}}
						>
							Kontakt
						</Link>
					</li>
					<li className="my-4">
						<Link
							to={process.env.PUBLIC_URL + "/datenschutz"}
							className="header-item-mobile"
							onClick={() => {
								try {
									// eslint-disable-next-line
									let dataLayer = window["dataLayer"] || []
									dataLayer.push({ event: "header", link: "kontakt" })
								} catch (e) {}
								return setShowMenu(false)
							}}
						>
							Datenschutzbestimmungen
						</Link>
					</li>
				</ul>
			</div>
		</div>
	)
}

export default MobileMenu
