import { useContext } from "react"
import * as React from "react"
import { Context } from "../../../store/store"
import ElemCheckboxMafo from "../../elements/CheckboxMafo"

interface MafoQuestionProps {
	formElementChanged: Function
	validation: Object
}
const MafoQuestionFour: React.FC<MafoQuestionProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)
	return (
		<>
			<h3 className="mb-6 text-left text-[17px] text-red lg:text-center lg:text-[22px]">Wie alt bist du?</h3>
			<div className="mx-auto text-left lg:max-w-[100%]">
				<ElemCheckboxMafo
					label={<>18-29</>}
					props={{
						type: "radio",
						name: "question4",
						value: "a",
						onClick: e => formElementChanged(e, "question4"),
					}}
					hasError={validation["invalid_question4"] ? true : false}
					className="pb-2 text-[32px] text-green lg:mt-6"
					errMsg={validation["invalid_question4_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>30-39</>}
					props={{
						type: "radio",
						name: "question4",
						value: "b",
						onClick: e => formElementChanged(e, "question4"),
					}}
					hasError={validation["invalid_question4"] ? true : false}
					className=" pb-2 text-green"
					errMsg={validation["invalid_question4_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>40-49</>}
					props={{
						type: "radio",
						name: "question4",
						value: "c",
						onClick: e => formElementChanged(e, "question4"),
					}}
					hasError={validation["invalid_question4"] ? true : false}
					className=" pb-2 text-green"
					errMsg={validation["invalid_question4_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>50-59</>}
					props={{
						type: "radio",
						name: "question4",
						value: "d",
						onClick: e => formElementChanged(e, "question4"),
					}}
					hasError={validation["invalid_question4"] ? true : false}
					className=" pb-2 text-green"
					errMsg={validation["invalid_question4_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>60+</>}
					props={{
						type: "radio",
						name: "question4",
						value: "e",
						onClick: e => formElementChanged(e, "question4"),
					}}
					hasError={validation["invalid_question4"] ? true : false}
					className=" pb-2 text-green"
					errMsg={validation["invalid_question4_errMsg"] || ""}
				/>
			</div>
		</>
	)
}

export default MafoQuestionFour
