import { Link } from "react-router-dom"
import React from "react"

function Footer() {
	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "footer", link: label })
		} catch (e) {}
	}

	return (
		<footer className="relative items-center  bg-white  lg:z-[300]">
			<section>
				<div className="mx-auto mt-12 mb-12 max-w-[1400px]">
					<h2 className="mb-6 text-center font-Veener text-[58px] uppercase text-red">Folge uns auf:</h2>
					<div className="mx-auto mb-12 grid w-[50%] grid-cols-2 justify-items-center self-center lg:mb-24 lg:w-[20%] ">
						<a href="https://www.instagram.com/volvic_de/" target="_blank" className="pointer underline">
							<img src={process.env.PUBLIC_URL + "/images/volvic/social/instagram.svg"} alt="Instagram" className="w-[75px]" />
						</a>
						<a href="https://de-de.facebook.com/Volvic/" target="_blank" className="pointer underline">
							<img src={process.env.PUBLIC_URL + "/images/volvic/social/facebook.svg"} alt="Instagram" className="w-[75px]" />
						</a>
					</div>
				</div>
			</section>
			<section className="-mt-24 grid h-[500px] w-full items-end bg-[url(./bgimages/volvic/bg-footer-mobile.png)] bg-cover bg-bottom lg:mt-0 lg:bg-[url(./bgimages/volvic/bg-footer-desktop.png)]">
				<div className="mt-8 flex flex-col items-center justify-center py-4  font-Veener text-[16px] lg:mt-0 lg:flex-row lg:text-[22px]">
					<Link to={"/teilnahmebedingungen"} className="mb-2 text-white lg:mr-12" onClick={() => track("Teilnahmebedingungen")}>
						Teilnahmebedingungen
					</Link>
					<Link to={"/faq"} className="mb-2 text-white lg:mr-12" onClick={() => track("FAQ")}>
						FAQS
					</Link>
					<Link to={"/kontakt"} className="mb-2 text-white lg:mr-12" onClick={() => track("Kontakt")}>
						Kontakt
					</Link>
					<Link to={"/kontakt"} className="mb-2 text-white lg:mr-12" onClick={() => track("Datenschutz")}>
						Datenschutzbestimmungen
					</Link>
					<Link to={"/impressum"} className="mb-2 text-white lg:mr-12" onClick={() => track("Impressum")}>
						Impressum
					</Link>
				</div>
			</section>
		</footer>
	)
}

export default Footer
