import React, { useEffect } from "react"
import Header from "../components/Header"
import PreHero from "./PreHero"
import { Link } from "react-router-dom"

const Confirm: React.FC = () => {
	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "footer", link: label })
		} catch (e) {}
	}

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<>
			<div className="light bg-red">
				<Header />
				<PreHero />
				<div className="bg-norepeat  relative z-[1200] w-full bg-red  bg-[url(./bgimages/volvic/bg-form.png)] bg-cover bg-center bg-repeat-y pt-[0px] lg:h-[500px] lg:pt-[0px]">
					<div className=" mx-auto max-w-[1400px] pt-12 text-center text-white lg:pt-6">
						<h3 className="mb-12 text-[24px]">Die Daten wurden erfolgreich übermittelt.</h3>
						<p className="mb-12">
							Du erhältst per E-Mail eine Eingangsbestätigung. <br />
							Anschließend werden wir die Einhaltung der Teilnahmevoraussetzungen prüfen.
						</p>
						<p className="mb-12 lg:mb-14">
							Viele Grüße <br />
							Dein Volvic Vitamin+ Team
						</p>
						<div className="pb-12">
							<Link to="/" className="btn mt-24 mb-24 font-Veener text-[42px] uppercase">
								{" "}
								Zurück zur Aktion
							</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default Confirm
