import * as React from "react"
import { motion, useInView, useMotionValueEvent, useScroll, useTransform } from "framer-motion"

interface HeroProps {
	type?: any
}

const Hero: React.FC<HeroProps> = ({ type = "" }) => {
	return (
		<section className=" lg:bg-center-bottom top-0 z-10 mx-auto h-[780px] w-full bg-white bg-[url(./bgimages/volvic/bg-hero-mobile-new.png)] bg-cover bg-center lg:bg-[url(./bgimages/volvic/hero-bg.png)]">
			<div className="mx-auto mt-[95px] grid max-w-[1400px] py-0 lg:mt-[180px] lg:grid-cols-2 lg:px-12 lg:py-0">
				<div className="relative px-6 py-6 lg:px-0 lg:py-0">
					<motion.img
						initial={{ opacity: 0, x: -100 }}
						animate={{ opacity: 1, x: 0 }}
						transition={{
							duration: 0.8,
							ease: "easeIn",
							delay: 0.5,
						}}
						src={process.env.PUBLIC_URL + "/images/volvic/intro.png"}
						className="item-center mb-5 mt-6 w-[180px] self-center lg:mt-24 lg:w-[75%]"
					/>
					{type === "prescreen" && (
						<img
							src={process.env.PUBLIC_URL + "/images/volvic/promo-soon-label.png"}
							className="item-end absolute -bottom-14 -left-2 w-[350px] self-center lg:relative lg:-mt-20 lg:ml-12 lg:w-[100%]"
						/>
					)}
					{type === "promoover" && (
						<img
							src={process.env.PUBLIC_URL + "/images/volvic/promo-over-label.png"}
							className="item-end absolute -bottom-12 -left-2 w-[350px] self-center lg:relative lg:-mt-20 lg:ml-12 lg:w-[100%]"
						/>
					)}
					<img src={process.env.PUBLIC_URL + "/images/volvic/maxlimit.svg"} className="absolute top-[1%] right-[30%] w-[25%] md:left-[62%] md:top-[20%] md:w-[22%]" />
					<img src={process.env.PUBLIC_URL + "/images/volvic/volvic-stoerer.png"} className="z-1 absolute top-[20%] right-[5%] w-[34%] md:left-[74%] md:top-[5%] md:w-[25%]" />
				</div>
				<motion.section
					variants={{
						hidden: { opacity: 0 },
						show: {
							opacity: 1,
							transition: {
								staggerChildren: 0.25,
							},
						},
					}}
					className={"relative grid grid-cols-3 px-4" + (type === "promoover" || type === "prescreen" ? " mt-12" : "")}
				>
					<motion.img
						initial={{ rotate: 0 }}
						animate={{ rotate: -3 }}
						transition={{
							duration: 0.8,
							ease: "easeIn",
							delay: 0.5,
						}}
						src={process.env.PUBLIC_URL + "/images/volvic/Himbeere.png"}
						className="item-center relative z-[10] mt-6 w-[300px]  -rotate-6 self-center lg:mt-24 lg:w-[100%]"
					/>

					<motion.img
						initial={{ rotate: 0, x: -200, opacity: 0 }}
						animate={{ rotate: 4, x: -10, opacity: 1 }}
						transition={{
							duration: 0.8,
							ease: "easeIn",
							delay: 0.75,
						}}
						src={process.env.PUBLIC_URL + "/images/volvic/Zitrone.png"}
						className="item-center relative z-[6]  -ml-[0.5rem] -mt-4 w-[300px] self-center lg:ml-1 lg:mt-24 lg:w-[85%]"
					/>
					<motion.img
						initial={{ rotate: 0, x: -340, opacity: 0 }}
						animate={{ rotate: 10, x: -8, opacity: 1 }}
						transition={{
							duration: 0.8,
							ease: "easeIn",
							delay: 1,
						}}
						src={process.env.PUBLIC_URL + "/images/volvic/Pfirsich.png"}
						className="item-center relative z-[4] -mt-8  -ml-[1rem] w-[300px] rotate-6 self-center lg:-ml-8 lg:mt-24 lg:w-[75%]"
					/>
					<motion.img
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{
							duration: 0.8,
							ease: "easeIn",
							delay: 1.2,
						}}
						src={process.env.PUBLIC_URL + "/images/volvic/sugar-hero-label.png"}
						className="item-center absolute -right-2 bottom-2 z-[15] w-[200px] self-center lg:bottom-24 lg:-right-24 lg:mt-24 lg:w-[40%]"
					/>
				</motion.section>
			</div>
		</section>
	)
}

export default Hero
