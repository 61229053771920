import React, { useEffect } from "react"
import DocumentMeta from "react-document-meta"
import { useNavigate, Link } from "react-router-dom"
import RosaSection from "../components/elements/BeigeSection"
import Header from "../components/Header"
const Imprint: React.FC = () => {
	const navigate = useNavigate()

	// SEO Meta Tags

	const meta = {
		// title: "Mit merci zum Muttertag filmreif Danke sagen!",
		// canonical: "https://arvato-hawaiian-tropic-2023.de/",
		// meta: {
		// 	name: {
		// 		robots: "noindex, nofollow",
		// 	},
		// },
	}

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<DocumentMeta {...meta}>
			<div className="light">
				<Header type="logo" />
				<div className="container mx-auto max-w-[800px] py-8 px-4 text-red">
					<h2 className="h2 mb-8 mt-32 text-center font-Veener text-2xl text-red lg:mt-64">Impressum</h2>
					<h2 className="mt-8 mb-2 text-[20px] font-bold font-bold leading-tight">Verantwortlich</h2>
					<p className=" mb-4">
						Danone Deutschland GmbH
						<br />
						Amtsgericht Frankfurt am Main, HRB: 112989 USt-ID Nr.: DE175657037
						<br />
						Bio-Kontrollstelle: DE-ÖKO-001
						<br />
						Geschäftsführer: Richard Trechman, Marc Widmer
					</p>
					<h2 className="mt-8 mb-2 text-[20px] font-bold leading-tight">Gewinnspiel-Support</h2>
					<p className=" mb-4">
						Bei Fragen und technischen Problemen melde dich bitte direkt <br /> bei unserem Support-Team:{" "}
						<a href="mailto:info@volvic-gratis-testen.de" className="underline">
							info@volvic-gratis-testen.de
						</a>
					</p>
					<h2 className="mt-8 mb-2 text-[20px] font-bold leading-tight">Verbraucherservice</h2>
					<p className=" mb-4">
						Kostenfreie Hotline <br />
						Montag-Freitag 08:00 - 20:00 Uhr: 0800 5557979 <br />
						Bei allgemeinen Fragen zu unseren Produkten:{" "}
						<a href="mailto:info@volvic.de" className="underline">
							info@volvic.de
						</a>
					</p>
					<h2 className="mt-8 mb-2 text-[20px] font-bold leading-tight">Postanschrift</h2>
					<p className=" mb-4">
						Danone Deutschland GmbH
						<br />
						Am Hauptbahnhof 18
						<br />
						60329 Frankfurt am Main
					</p>
				</div>
				<div className=" py-10 pt-5 text-center text-xl text-white">
					<Link to="/" className="btn btn-hero mt-8 rounded bg-white px-12 py-4 text-white">
						Zurück
					</Link>
				</div>
			</div>
		</DocumentMeta>
	)
}
export default Imprint
