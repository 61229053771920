import * as React from "react"

interface PreHeroProps {
	type?: any
}
const PreHero: React.FC<PreHeroProps> = ({ type }) => {
	return (
		<section className=" lg:bg-center-bottom relative top-0 z-[300] z-10 mx-auto h-[540px] w-full bg-red bg-[url(./bgimages/volvic/bg-hero-mobile-new.png)] bg-cover bg-center lg:-mt-12 lg:h-[750px] lg:bg-white lg:bg-[url(./bgimages/volvic/header-prehero.png)]">
			<div className="mx-auto mt-[95px] grid max-w-[1400px] py-0 lg:mt-[180px] lg:grid-cols-2 lg:px-12 lg:py-0">
				<div className="mt-12 px-12 py-6 lg:mt-24 lg:px-0 lg:py-0">
					<p className="text-center font-Veener text-[32px] text-green lg:text-[62px]">
						Vielen Dank für die Teilnahme an unserer <span className="text-red">GRATIS</span>-TESTEN-AKTION.
					</p>
				</div>
				<div className="grid grid-cols-3 px-4 lg:mt-24">
					<img src={process.env.PUBLIC_URL + "/images/volvic/Himbeere.png"} className="item-end z-4 relative ml-12 w-[90px] -rotate-6 self-center lg:mt-24 lg:w-[80%]" />
					<img src={process.env.PUBLIC_URL + "/images/volvic/Zitrone.png"} className="item-center z-6 relative w-[90px] self-center lg:mt-24 lg:w-[80%]" />
					<img src={process.env.PUBLIC_URL + "/images/volvic/Pfirsich.png"} className="item-center relative -ml-12 w-[90px] rotate-6 self-center lg:-ml-8 lg:mt-24 lg:w-[80%]" />
				</div>
			</div>
		</section>
	)
}

export default PreHero
