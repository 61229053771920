import axios from "axios"
const domains = {
	"www.volvic-gratis-testen.de": {
		env: "prod",
		apibase: "/",
	},
	"int.fe.volvic-vitamin-plus-frontend.campaign-loyalty.com": {
		env: "inte",
		apibase: "/inte/",
	},

	"prod.fe.volvic-vitamin-plus-frontend.campaign-loyalty.com": {
		env: "prod",
		apibase: "/",
	},
}
export const getEnvConfig = () => {
	return domains[window.location.host] || { apibase: "/inte/" }
}
export async function checkOrderCount() {
	const env = getEnvConfig()
	return await axios
		.get(env.apibase + "checkOrder.php?cv=" + Date.now())
		.then(res => {
			let digits = Array.from(String(res.data.ordersLeft))
			const arrOfNums = digits.map(str => Number(str))
			return digits
		})
		.catch(error => {
			return error.response.status
		})
}
export async function getPromoLimit() {
	const env = getEnvConfig()
	return await axios
		.get(env.apibase + "checkLimit.php?cv=" + Date.now())
		.then(res => {
			console.log(res)
			return res.data.limitReached
		})
		.catch(error => {
			return error.response.status
		})
}
export async function getWeeklyLimit() {
	const env = getEnvConfig()
	return await axios
		.get(env.apibase + "checkWeeklyLimit.php?cv=" + Date.now())
		.then(res => {
			console.log(res)
			return res.data.limitReached
		})
		.catch(error => {
			return error.response.status
		})
}
