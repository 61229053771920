import { useContext } from "react"
import { Link } from "react-router-dom"
import * as React from "react"
import { Context } from "../../store/store"
import ElemCheckbox from "../elements/Checkbox"

interface LegalProps {
	formElementChanged: Function
	validation: Object
}
const Legal: React.FC<LegalProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)
	return (
		<div className=" mx-auto max-w-[900px]  lg:mt-24 ">
			<ElemCheckbox
				label={
					<>
						Ich habe die{" "}
						<Link
							to="/teilnahmebedingungen"
							target="_blank"
							className="underline"
							onClick={() => {
								try {
									// eslint-disable-next-line
									let dataLayer = window["dataLayer"] || []
									dataLayer.push({ event: "form", link: "teilnahmebedingungen" })
								} catch (e) {}
							}}
						>
							Teilnahmebedingungen
						</Link>{" "}
						gelesen und akzeptiere diese. *
					</>
				}
				props={{
					type: "checkbox",
					name: "terms",
					required: "required",
					value: globalState.terms,
					onClick: e => formElementChanged(e, "terms"),
				}}
				hasError={validation["invalid_terms"] ? true : false}
				className="<mb-6></mb-6> pb-6"
				errMsg={validation["invalid_terms_errMsg"] || "Bitte Teilnahmebedingungen bestätigen."}
			/>
			<ElemCheckbox
				label={<>Ich bin mindestens 18 Jahre alt. *</>}
				props={{
					type: "checkbox",
					name: "optInAge",
					required: "required",
					value: globalState.optInAge,
					onClick: e => formElementChanged(e, "optInAge"),
				}}
				hasError={validation["invalid_optInAge"] ? true : false}
				className="pb-6"
				errMsg={validation["invalid_optInAge_errMsg"] || "Bitte Alter bestätigen."}
			/>
		</div>
	)
}

export default Legal
