import { useContext } from "react"
import * as React from "react"
import { Context } from "../../../store/store"
import ElemCheckboxMafo from "../../elements/CheckboxMafo"

interface MafoQuestionProps {
	formElementChanged: Function
	validation: Object
}
const MafoQuestionFive: React.FC<MafoQuestionProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)
	return (
		<>
			<h3 className="mb-6 text-left text-[17px] text-red lg:text-center lg:text-[22px]">Würdest du das Volvic Produkt wieder kaufen?</h3>
			<div className="mx-auto text-left lg:max-w-[100%]">
				<ElemCheckboxMafo
					label={<>Sehr wahrscheinlich</>}
					props={{
						type: "radio",
						name: "question5",
						value: "a",
						onClick: e => formElementChanged(e, "question5"),
					}}
					hasError={validation["invalid_question5"] ? true : false}
					className=" pb-2 text-green"
					errMsg={validation["invalid_question5_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>Wahrscheinlich</>}
					props={{
						type: "radio",
						name: "question5",
						value: "b",
						onClick: e => formElementChanged(e, "question5"),
					}}
					hasError={validation["invalid_question5"] ? true : false}
					className=" pb-2 text-green"
					errMsg={validation["invalid_question5_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>Eher unwahrscheinlich</>}
					props={{
						type: "radio",
						name: "question5",
						value: "c",
						onClick: e => formElementChanged(e, "question5"),
					}}
					hasError={validation["invalid_question5"] ? true : false}
					className=" pb-2 text-green"
					errMsg={validation["invalid_question5_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>Unwahrscheinlich</>}
					props={{
						type: "radio",
						name: "question5",
						value: "d",
						onClick: e => formElementChanged(e, "question5"),
					}}
					hasError={validation["invalid_question5"] ? true : false}
					className=" pb-2 text-green"
					errMsg={validation["invalid_question5_errMsg"] || ""}
				/>
			</div>
		</>
	)
}

export default MafoQuestionFive
