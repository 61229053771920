import { Link } from "react-router-dom"
import React from "react"
import Slider from "react-slick"
import "../slickslider.css"

function Info() {
	var settings = {
		dots: false,
		infinite: true,
		speed: 100,
		slidesToShow: 3,
		slidesToScroll: 1,
		swipeToSlide: true,
		initialSlide: 0,
		arrows: false,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					dots: false,
				},
			},
			{
				breakpoint: 480,
				settings: {
					autoplay: true,
					autoplaySpeed: 3000,
					slidesToShow: 1.04,
					dots: false,
					speed: 300,
					infinite: true,
					slidesToScroll: 1,
				},
			},
		],
	}
	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "footer", link: label })
		} catch (e) {}
	}

	return (
		<div>
			<div className="mx-auto mt-12 mb-12 max-w-[1400px] p-6 lg:mt-24">
				<h2 className="mb-6 mt-12 text-center font-Veener text-[32px] uppercase text-green lg:mt-6 lg:mb-12 lg:text-[58px]">Neu – in drei leckeren Sorten</h2>
			</div>
			<div className="w-full">
				<Slider {...settings}>
					<div className=" bg-[url(./bgimages/volvic/bg-recharge.png)] bg-cover bg-center p-6 pb-24">
						<div className="grid grid-cols-[40%_60%] lg:grid-cols-2">
							<div className="relative items-center self-center">
								<img src={process.env.PUBLIC_URL + "/images/volvic/Himbeere.png"} className="z-2 relative -mt-24 max-w-[70%] lg:ml-12 lg:max-w-[50%]" />
							</div>
							<div className="self-center">
								<p className="mb-0 font-Veener text-[2.75rem] uppercase leading-[62px] text-white lg:text-[4.5rem] lg:leading-[82px]">RECHARGE</p>
								<p className="mt-0 font-Veener text-[24px] uppercase text-white lg:text-[32px]">HIMBEERGESCHMACK</p>
								<div className="mt-2 grid grid-cols-3 gap-1">
									<div className="block h-[54px] w-[54px] rounded-full bg-white p-3 text-center font-Veener text-[24px] text-[#BD1952] lg:h-[68px] lg:w-[75px] lg:text-[32px]">
										B5
									</div>
									<div className="block h-[54px] w-[54px] rounded-full bg-white p-3 text-center font-Veener text-[24px] text-[#BD1952] lg:h-[68px] lg:w-[75px] lg:text-[32px]">
										B6
									</div>
									<div className="block h-[54px] w-[54px] rounded-full bg-white p-3 text-center font-Veener text-[24px] text-[#BD1952] lg:h-[68px] lg:w-[75px] lg:text-[32px]">
										B12
									</div>
								</div>
								<p className="mt-6 inline-block rounded-full bg-white px-6 py-2 font-Veener text-[36px] text-[#BD1952]">MAGNESIUM</p>
							</div>
						</div>
					</div>
					<div className="grid grid-cols-2 bg-[url(./bgimages/volvic/bg-awake.png)] bg-cover bg-center p-6 pb-24">
						<div className="grid grid-cols-[40%_60%] lg:grid-cols-2">
							<div className="relative items-center self-center">
								<img src={process.env.PUBLIC_URL + "/images/volvic/Zitrone.png"} className="z-2 relative -mt-24 ml-2 max-w-[70%] lg:ml-12 lg:max-w-[50%]" />
							</div>
							<div className="self-center">
								<p className="mb-0 font-Veener text-[2.75rem] uppercase leading-[62px] text-white lg:text-[4.5rem] lg:leading-[82px]">AWAKE</p>
								<p className="mt-0 font-Veener text-[24px] uppercase text-white lg:text-[32px]">ZITRONENGESCHMACK</p>
								<div className="mt-2 grid grid-cols-3 gap-1">
									<div className="block h-[54px] w-[54px] rounded-full bg-white p-3 text-center font-Veener text-[24px] text-[#F4A606] lg:h-[68px] lg:w-[75px]  lg:text-[32px] ">
										B3
									</div>
									<div className="block h-[54px] w-[54px] rounded-full bg-white p-3 text-center font-Veener text-[24px] text-[#F4A606] lg:h-[68px] lg:w-[75px] lg:text-[32px]">
										B6
									</div>
									<div className="block h-[54px] w-[54px] rounded-full bg-white p-3 text-center font-Veener text-[24px] text-[#F4A606] lg:h-[68px] lg:w-[75px] lg:text-[32px]">
										B9
									</div>
								</div>
								<p className="mt-6 inline-block rounded-full bg-white px-6 py-2 font-Veener text-[36px] text-[#F4A606]">MAGNESIUM</p>
							</div>
						</div>
					</div>
					<div className="grid grid-cols-2 bg-[url(./bgimages/volvic/bg-active.png)] bg-cover bg-center p-6 pb-24">
						<div className="grid grid-cols-[40%_60%] lg:grid-cols-2">
							<div className="relative items-center self-center">
								<img src={process.env.PUBLIC_URL + "/images/volvic/Pfirsich.png"} className="z-2 relative -mt-24 max-w-[70%] lg:ml-12 lg:max-w-[50%]" />
							</div>
							<div className="self-center">
								<p className="mb-0 font-Veener text-[2.75rem] uppercase leading-[62px] text-white lg:text-[4.5rem] lg:leading-[82px]">ACTIVE</p>
								<p className="mt-0 font-Veener text-[24px] uppercase text-white lg:text-[32px]">PFIRSICHGESCHMACK</p>
								<div className="mt-2 grid grid-cols-3 gap-1">
									<div className="block h-[54px] w-[54px] rounded-full bg-white p-3 text-center font-Veener text-[24px] text-[#DF5218] lg:h-[68px] lg:w-[75px]  lg:text-[32px]">
										C
									</div>
									<div className="block h-[54px] w-[54px] rounded-full bg-white p-3 text-center font-Veener text-[24px] text-[#DF5218] lg:h-[68px] lg:w-[75px]  lg:text-[32px]">
										B12
									</div>
									<div className="block h-[54px] w-[54px] rounded-full bg-white p-3 text-center font-Veener text-[24px] text-[#DF5218] lg:h-[68px] lg:w-[75px]  lg:text-[32px]">
										B8
									</div>
								</div>
								<p className="mt-6 inline-block rounded-full bg-white px-6 py-2 font-Veener text-[36px] text-[#DF5218]">MAGNESIUM</p>
							</div>
						</div>
					</div>
				</Slider>
				<div className="rounded-border relative p-6">
					<p className="mt-12 mb-12 text-center font-Noto text-[28px] font-semibold text-green lg:mt-24">Du findest Vitamin+ in den folgenden Märkten:</p>
					<div className="mx-auto mb-12 grid max-w-[90%] grid-cols-2  items-center justify-items-center gap-4 lg:mb-24 lg:max-w-[40%] lg:grid-cols-4">
						<div>
							<img src={process.env.PUBLIC_URL + "/images/volvic/marken/rewe.png"} className="w-[150px]" />
						</div>
						<div>
							<img src={process.env.PUBLIC_URL + "/images/volvic/marken/edeka.png"} className="h-[100px] lg:h-[75px]" />
						</div>
						<div>
							<img src={process.env.PUBLIC_URL + "/images/volvic/marken/aral.png"} className="h-[100px] lg:h-[75px]" />
						</div>
						<div>
							<img src={process.env.PUBLIC_URL + "/images/volvic/marken/mueller.png"} className="h-[100px] lg:h-[75px]" />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Info
