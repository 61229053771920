import { useContext } from "react"
import * as React from "react"
import { Context } from "../../../store/store"
import ElemCheckboxMafo from "../../elements/CheckboxMafo"

interface MafoQuestionProps {
	formElementChanged: Function
	validation: Object
}
const MafoQuestionTwo: React.FC<MafoQuestionProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)
	return (
		<>
			<h3 className="mb-6 text-left text-[17px] text-red lg:text-center lg:text-[22px]">Wie hast du von der Aktion erfahren?*</h3>
			<div className="mx-auto text-left lg:max-w-[100%]">
				<ElemCheckboxMafo
					label={<>Am Aktionsaufsteller</>}
					props={{
						type: "radio",
						name: "question2",
						value: "a",
						required: "required",
						onClick: e => formElementChanged(e, "question2"),
					}}
					className=" pb-2 text-green"
				/>
				<ElemCheckboxMafo
					label={<>Handzettel</>}
					props={{
						type: "radio",
						name: "question2",
						value: "b",
						required: "required",
						onClick: e => formElementChanged(e, "question2"),
					}}
					className="pb-2 text-green"
				/>
				<ElemCheckboxMafo
					label={<>Werbeplakat außerhalb von Markt</>}
					props={{
						type: "radio",
						name: "question2",
						value: "c",
						required: "required",
						onClick: e => formElementChanged(e, "question2"),
					}}
					className=" pb-2 text-green"
				/>
				<ElemCheckboxMafo
					label={<>(Digitale) Werbefläche im Markt</>}
					props={{
						type: "radio",
						name: "question2",
						value: "d",
						required: "required",
						onClick: e => formElementChanged(e, "question2"),
					}}
					className=" pb-2 text-green"
				/>
				<ElemCheckboxMafo
					label={<>Deal Website</>}
					props={{
						type: "radio",
						name: "question2",
						value: "e",
						required: "required",
						onClick: e => formElementChanged(e, "question2"),
					}}
					className=" pb-2 text-green"
				/>
				<ElemCheckboxMafo
					label={<>Volvic Website</>}
					props={{
						type: "radio",
						name: "question2",
						value: "f",
						required: "required",
						onClick: e => formElementChanged(e, "question2"),
					}}
					className=" pb-2 text-green"
				/>
				<ElemCheckboxMafo
					label={<>Social Media</>}
					props={{
						type: "radio",
						name: "question2",
						value: "g",
						required: "required",
						onClick: e => formElementChanged(e, "question2"),
					}}
					className=" pb-2 text-green"
				/>
				<ElemCheckboxMafo
					label={<>Durch Bekannte/Freunde</>}
					props={{
						type: "radio",
						name: "question2",
						value: "h",
						required: "required",
						onClick: e => formElementChanged(e, "question2"),
					}}
					className=" pb-2 text-green"
				/>
				<ElemCheckboxMafo
					label={<>Sonstiges</>}
					props={{
						type: "radio",
						name: "question2",
						value: "i",
						required: "required",
						onClick: e => formElementChanged(e, "question2"),
					}}
					className=" pb-2 text-green"
				/>
				{globalState.question2 === "i" && (
					<>
						<textarea
							name="question2Other"
							maxLength={99}
							required
							className="h-[150px] w-full bg-[#F7F7F7] py-4 px-2"
							onChange={e => formElementChanged(e, "question2Other")}
						></textarea>
					</>
				)}
			</div>
		</>
	)
}

export default MafoQuestionTwo
