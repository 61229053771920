import { useContext } from "react"
import * as React from "react"
import { Context } from "../../../store/store"
import ElemCheckboxMafo from "../../elements/CheckboxMafo"

interface MafoQuestionProps {
	formElementChanged: Function
	validation: Object
}
const MafoQuestionNine: React.FC<MafoQuestionProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)
	return (
		<>
			<h3 className="mb-6 text-left text-[17px] text-red lg:text-center lg:text-[22px]">Wann trinkst du Volvic Vitamin+? (Mehrfachantwort möglich)</h3>
			<div className="mx-auto text-left lg:max-w-[100%]">
				<ElemCheckboxMafo
					label={<>Zum Durst löschen</>}
					props={{
						type: "checkbox",
						name: "question9",
						value: "a",
						onClick: e => formElementChanged(e, "question9"),
					}}
					hasError={validation["invalid_question9"] ? true : false}
					className=" question9 pb-2 text-green"
					errMsg={validation["invalid_question9_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>Beim Entspannen</>}
					props={{
						type: "checkbox",
						name: "question9",
						value: "b",
						onClick: e => formElementChanged(e, "question9"),
					}}
					hasError={validation["invalid_question9"] ? true : false}
					className=" question9 pb-2 text-green"
					errMsg={validation["invalid_question9_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>Zum Energie tanken</>}
					props={{
						type: "checkbox",
						name: "question9",
						value: "c",
						onClick: e => formElementChanged(e, "question9"),
					}}
					hasError={validation["invalid_question9"] ? true : false}
					className=" question9 pb-2 text-green"
					errMsg={validation["invalid_question9_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>Morgens</>}
					props={{
						type: "checkbox",
						name: "question9",
						value: "d",
						onClick: e => formElementChanged(e, "question9"),
					}}
					hasError={validation["invalid_question9"] ? true : false}
					className=" question9 pb-2 text-green"
					errMsg={validation["invalid_question9_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>Mittags</>}
					props={{
						type: "checkbox",
						name: "question9",
						value: "e",
						onClick: e => formElementChanged(e, "question9"),
					}}
					hasError={validation["invalid_question9"] ? true : false}
					className=" question9 pb-2 text-green"
					errMsg={validation["invalid_question9_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>Abends</>}
					props={{
						type: "checkbox",
						name: "question9",
						value: "f",
						onClick: e => formElementChanged(e, "question9"),
					}}
					hasError={validation["invalid_question9"] ? true : false}
					className=" question9 pb-2 text-green"
					errMsg={validation["invalid_question9_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>An einem stressigen Tag</>}
					props={{
						type: "checkbox",
						name: "question9",
						value: "g",
						onClick: e => formElementChanged(e, "question9"),
					}}
					hasError={validation["invalid_question9"] ? true : false}
					className=" question9 pb-2 text-green"
					errMsg={validation["invalid_question9_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>Nach oder während dem Sport</>}
					props={{
						type: "checkbox",
						name: "question9",
						value: "h",
						onClick: e => formElementChanged(e, "question9"),
					}}
					hasError={validation["invalid_question9"] ? true : false}
					className=" question9 pb-2 text-green"
					errMsg={validation["invalid_question9_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>Wenn ich mir bewusst Zeit für meine Gesundheit und mein Wohlbefinden nehme</>}
					props={{
						type: "checkbox",
						name: "question9",
						value: "i",
						onClick: e => formElementChanged(e, "question9"),
					}}
					hasError={validation["invalid_question9"] ? true : false}
					className=" question9 pb-2 text-green"
					errMsg={validation["invalid_question9_errMsg"] || ""}
				/>
			</div>
		</>
	)
}

export default MafoQuestionNine
