import { useContext } from "react"
import * as React from "react"
import { Context } from "../../../store/store"
import ElemCheckboxMafo from "../../elements/CheckboxMafo"

interface MafoQuestionProps {
	formElementChanged: Function
	validation: Object
}
const MafoQuestionThree: React.FC<MafoQuestionProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)
	return (
		<>
			<h3 className="mb-6 text-left text-[17px] text-red lg:text-center lg:text-[22px]">Welche Aussage trifft auf dich in Bezug auf dein Einkaufsverhalten von Volvic zu?</h3>
			<div className="mx-auto text-left lg:max-w-[100%]">
				<ElemCheckboxMafo
					label={<>Ich kaufe Volvic wöchentlich</>}
					props={{
						type: "radio",
						name: "question3",
						value: "a",
						onClick: e => formElementChanged(e, "question3"),
					}}
					hasError={validation["invalid_question3"] ? true : false}
					className=" pb-2 text-green"
					errMsg={validation["invalid_question3_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>Ich kaufe Volvic mehrfach im Monat</>}
					props={{
						type: "radio",
						name: "question3",
						value: "b",
						onClick: e => formElementChanged(e, "question3"),
					}}
					hasError={validation["invalid_question3"] ? true : false}
					className=" pb-2 text-green"
					errMsg={validation["invalid_question3_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>Ich kaufe Volvic mehrfach im Jahr</>}
					props={{
						type: "radio",
						name: "question3",
						value: "c",
						onClick: e => formElementChanged(e, "question3"),
					}}
					hasError={validation["invalid_question3"] ? true : false}
					className=" pb-2 text-green"
					errMsg={validation["invalid_question3_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>Ich habe Volvic früher gekauft (zuletzt vor mehr als 6 Monaten)</>}
					props={{
						type: "radio",
						name: "question3",
						value: "d",
						onClick: e => formElementChanged(e, "question3"),
					}}
					hasError={validation["invalid_question3"] ? true : false}
					className=" pb-2 text-green"
					errMsg={validation["invalid_question3_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>Ich probiere Volvic zum ersten Mal</>}
					props={{
						type: "radio",
						name: "question3",
						value: "e",
						onClick: e => formElementChanged(e, "question3"),
					}}
					hasError={validation["invalid_question3"] ? true : false}
					className=" pb-2 text-green"
					errMsg={validation["invalid_question3_errMsg"] || ""}
				/>
			</div>
		</>
	)
}

export default MafoQuestionThree
