import { useContext } from "react"
import * as React from "react"
import { Context } from "../../../store/store"
import ElemCheckboxMafo from "../../elements/CheckboxMafo"

interface MafoQuestionProps {
	formElementChanged: Function
	validation: Object
}
const MafoQuestionOne: React.FC<MafoQuestionProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)
	return (
		<>
			<h3 className="mb-6 text-left text-[17px] text-red lg:text-center lg:text-[22px]">Warum hast du Volvic dieses Mal gekauft?* (Mehrfachantwort möglich)</h3>
			<div className="mx-auto text-left lg:max-w-[100%]">
				<ElemCheckboxMafo
					label={<>Wegen der Gratis-Testen-Möglichkeit</>}
					props={{
						type: "checkbox",
						name: "question1",
						required: "required",
						value: "a",
						onClick: e => formElementChanged(e, "question1"),
					}}
					className=" question1 pb-2 text-green"
				/>
				<ElemCheckboxMafo
					label={<>Weil es eine Produktneuheit ist</>}
					props={{
						type: "checkbox",
						name: "question1",
						required: "required",
						value: "b",
						onClick: e => formElementChanged(e, "question1"),
					}}
					className=" question1 pb-2 text-green"
				/>
				<ElemCheckboxMafo
					label={<>Weil es ein funktionales Wasser mit Vitaminen ist</>}
					props={{
						type: "checkbox",
						name: "question1",
						required: "required",
						value: "c",
						onClick: e => formElementChanged(e, "question1"),
					}}
					className=" question1 pb-2 text-green"
				/>
				<ElemCheckboxMafo
					label={<>Ich kaufe Volvic regelmäßig</>}
					props={{
						type: "checkbox",
						name: "question1",
						required: "required",
						value: "d",
						onClick: e => formElementChanged(e, "question1"),
					}}
					className=" question1 pb-2 text-green"
				/>
				<ElemCheckboxMafo
					label={<>Volvic Produkte schmecken besser als andere Marken</>}
					props={{
						type: "checkbox",
						name: "question1",
						required: "required",
						value: "e",
						onClick: e => formElementChanged(e, "question1"),
					}}
					className=" question1 pb-2 text-green"
				/>
				<ElemCheckboxMafo
					label={<>Ich finde Volvic als Marke gut</>}
					props={{
						type: "checkbox",
						name: "question1",
						required: "required",
						value: "f",
						onClick: e => formElementChanged(e, "question1"),
					}}
					className=" question1 pb-2 text-green"
				/>
				<ElemCheckboxMafo
					label={<>Sonstiges</>}
					props={{
						type: "checkbox",
						name: "question1",
						required: "required",
						value: "g",
						onClick: e => formElementChanged(e, "question1"),
					}}
					className=" question1 pb-2 text-green"
				/>{" "}
				{globalState.question1.includes("g") && (
					<>
						<textarea name="question1Other" maxLength={99} className="h-[150px] w-full bg-[#F7F7F7] py-4 px-2" onChange={e => formElementChanged(e, "question1Other")}></textarea>
					</>
				)}
			</div>
		</>
	)
}

export default MafoQuestionOne
