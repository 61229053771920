import { useAutoAnimate } from "@formkit/auto-animate/react"
import React from "react"

interface InputProps {
	props?: any
	className?: string | null
	label?: string | null
	errMsg?: string
	hasError?: boolean
}

const ElemSelect: React.FC<InputProps> = ({ className, props, label, errMsg, hasError, children }) => {
	const [animationRef] = useAutoAnimate<HTMLDivElement>()
	return (
		<>
			{props.value !== "" && <span className={"text-bold  block pt-2 pl-3 pb-2" + (hasError ? "  text-white" : " text-white ")}>{label}</span>}
			<label className={"relative block " + (className || "")}>
				<select
					className={
						"  text-bold text-y outline- peer mb-2 w-full  appearance-none rounded-[20px] bg-white bg-[url('/src/bgimages/volvic/arrow-checkbox-green.svg')] bg-[160px_20px] bg-no-repeat py-3  px-4 text-green shadow-inner lg:mb-8" +
						(hasError ? "  js-formerror " : "")
					}
					{...props}
				>
					{children}
				</select>
				<div ref={animationRef}>{hasError && <div className="formErrorMsg bg-whiteLight75 mb-2 inline rounded-[10px] px-2 py-1 text-[12px] font-bold text-red">{errMsg}</div>}</div>
			</label>
		</>
	)
}

export default ElemSelect
