import { useContext } from "react"
import * as React from "react"
import { Context } from "../../../store/store"
import ElemCheckboxMafo from "../../elements/CheckboxMafo"

interface MafoQuestionProps {
	formElementChanged: Function
	validation: Object
}
const MafoQuestionEight: React.FC<MafoQuestionProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)
	return (
		<>
			<h3 className="mb-6 text-left text-[17px] text-red lg:text-center lg:text-[22px]">Warum trinkst du Volvic Vitamin+? (Mehrfachantwort möglich)</h3>
			<div className="mx-auto text-left lg:max-w-[100%]">
				<ElemCheckboxMafo
					label={<>Weil ich mich gut gelaunt und glücklich fühle</>}
					props={{
						type: "checkbox",
						name: "question8",
						value: "a",
						onClick: e => formElementChanged(e, "question8"),
					}}
					hasError={validation["invalid_question8"] ? true : false}
					className=" question8 pb-2 text-green"
					errMsg={validation["invalid_question8_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>Weil ich über mein Getränk ausdrücken möchte wer ich bin</>}
					props={{
						type: "checkbox",
						name: "question8",
						value: "b",
						onClick: e => formElementChanged(e, "question8"),
					}}
					hasError={validation["invalid_question8"] ? true : false}
					className=" question8 pb-2 text-green"
					errMsg={validation["invalid_question8_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>Hilft mir mein Bestes zu geben</>}
					props={{
						type: "checkbox",
						name: "question8",
						value: "c",
						onClick: e => formElementChanged(e, "question8"),
					}}
					hasError={validation["invalid_question8"] ? true : false}
					className=" question8 pb-2 text-green"
					errMsg={validation["invalid_question8_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>Hilft mir Energie zu tanken</>}
					props={{
						type: "checkbox",
						name: "question8",
						value: "d",
						onClick: e => formElementChanged(e, "question8"),
					}}
					hasError={validation["invalid_question8"] ? true : false}
					className=" question8 pb-2 text-green"
					errMsg={validation["invalid_question8_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>Weil ich ein erfrischendes Getränk möchte</>}
					props={{
						type: "checkbox",
						name: "question8",
						value: "e",
						onClick: e => formElementChanged(e, "question8"),
					}}
					hasError={validation["invalid_question8"] ? true : false}
					className=" question8 pb-2 text-green"
					errMsg={validation["invalid_question8_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>Weil ich müde bin</>}
					props={{
						type: "checkbox",
						name: "question8",
						value: "f",
						onClick: e => formElementChanged(e, "question8"),
					}}
					hasError={validation["invalid_question8"] ? true : false}
					className=" question8 pb-2 text-green"
					errMsg={validation["invalid_question8_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>Weil mir Motivation fehlt</>}
					props={{
						type: "checkbox",
						name: "question8",
						value: "g",
						onClick: e => formElementChanged(e, "question8"),
					}}
					hasError={validation["invalid_question8"] ? true : false}
					className=" question8 pb-2 text-green"
					errMsg={validation["invalid_question8_errMsg"] || ""}
				/>
				<ElemCheckboxMafo
					label={<>Weil ich mich gesund halten möchte</>}
					props={{
						type: "checkbox",
						name: "question8",
						value: "h",
						onClick: e => formElementChanged(e, "question8"),
					}}
					hasError={validation["invalid_question8"] ? true : false}
					className=" question8 pb-2 text-green"
					errMsg={validation["invalid_question8_errMsg"] || ""}
				/>
			</div>
		</>
	)
}

export default MafoQuestionEight
