import { useContext, useState } from "react"
import React from "react"
import { Context } from "../../store/store"
import Popup from "../elements/Popup"
import ReceiptUpload from "./ReceiptUpload"
import { useAutoAnimate } from "@formkit/auto-animate/react"

const Receipt = () => {
	const { dispatch } = useContext(Context)
	const [showSample, setShowSample] = useState<boolean>(false)
	const [animationRef] = useAutoAnimate<HTMLDivElement>()

	return (
		<>
			<section className="js-mitmachen relative mb-12 pt-8 sm:px-4 lg:mb-12">
				<div className="container text-center">
					<h2 className="mb-6 mt-2 text-center font-Veener text-[36px] uppercase text-white lg:mt-12 lg:mb-2 lg:text-[58px]">Kassenbon hochladen</h2>
					<p className="mx-auto mb-12 text-center text-[18px] leading-[22px] text-white lg:max-w-[65%] lg:text-[22px] lg:leading-[37px]">
						Achte darauf, dass der Kaufbeleg vollständig ist und Datum, Einkaufsstätte, Aktionsprodukt, Preis sowie Bonsumme gut lesbar sind.
					</p>
					<div className="p-1 sm:p-4">
						<div className="arrow-white mx-auto max-w-[600px] rounded-lg border-[2px] border-dashed border-white bg-whiteLight px-2 py-6 lg:py-16">
							<ReceiptUpload dispatch={dispatch} />
						</div>
					</div>
					<div>
						<p className="mx-auto mt-4 max-w-[600px] text-center text-white underline">
							<a className="cursor-pointer underline" onClick={() => setShowSample(true)}>
								Beispiel ansehen
							</a>
						</p>
					</div>
				</div>
				<div ref={animationRef}>
					{showSample && (
						<Popup close={() => setShowSample(false)}>
							<img src={process.env.PUBLIC_URL + "/images/kassenbon-beispiel-mobile.jpg"} alt="" className="mx-auto block h-auto lg:hidden" />
							<img src={process.env.PUBLIC_URL + "/images/kassenbon-beispiel.jpg"} alt="" className="mx-auto hidden h-auto lg:block" />
						</Popup>
					)}
				</div>
			</section>
		</>
	)
}

export default Receipt
